/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:56a450f7-6cc4-45f0-b9db-de54d38ab248",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_NfMRHWHUa",
    "aws_user_pools_web_client_id": "sdsofck51t3v79pqk03l9i5a3",
    "oauth": {}
};


export default awsmobile;
